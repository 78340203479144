window.main = window.main || {};

var cache;

main.cacheSelectors = function () {
    cache = {
        html                     : document.getElementsByTagName('html')[0],
        body                     : document.getElementsByTagName('body')[0],
        screensaver              : document.querySelector('.c-screensaver'),
        screensaverImages        : document.querySelectorAll('.c-screensaver__item'),
        screensaverFirstImage     : document.querySelector('.c-screensaver__item--first'),
        inactiveTime             : 0,
        activeImage              : 0,
        screensaverAnimation     : '',
        screensaverFirstImageTimeout : ''
    };
};

main.init = function () {
    main.cacheSelectors();
    main.map.initialize();
    main.modal();
    main.slider();
    main.forms();
    main.multiStepForm();
    main.screensaver();
};

main.modal = function () {
    var inlineAttribute = 'data-colorbox-inline';

    $('[' + inlineAttribute + ']').colorbox({
        width: '100%',
        height: '100%',
        inline: true,
        href: function(){
            return this.getAttribute(inlineAttribute);
        },
        fixed: true,
        scrolling: true,
        // open: true,
    });
};

main.slider = function () {
    $('.slider').slick({
        arrows: false,
        dots: true,
        customPaging: function(slider, i) {
            if (slider.$slides.length === 0) return;
            var thumb = slider.$slides[i].getAttribute('data-thumb');
            return '<img src="' + thumb + '">';
        },
    });
};

main.forms = function () {
    var forms = document.querySelectorAll('form');

    [].forEach.call(forms, function(form){
        form.addEventListener('submit', submit);
    });

    function submit() {
        this.classList.add('is-submitted');
    }
};

main.multiStepForm = function () {
    var form = document.querySelector('.js-form-steps');
    if (!form) return;

    var steps = form.querySelectorAll('.js-form-step');

    [].forEach.call(steps, function(step, i){
        if (i > 0) {
            step.style.display = 'none';
        }

        var nextStep = steps[i + 1];
        var trigger = step.querySelector('.js-form-step-next');

        if (trigger) {
            trigger.addEventListener('click', function(){
                step.style.display = 'none';

                if (nextStep === undefined) return;
                nextStep.style.display = '';
            });
        }
    });
};

main.screensaver = function() {

    /*
        1. after X minutes: remove hidden class
        2. loop the thingy for x seconds
        3. Tap the screen, reset the function
    */

    window.setInterval(main.handleShowScreensaver, 1000);
    window.addEventListener('click', main.hideScreensaver);
    window.addEventListener('touchstart', main.hideScreensaver);

};

main.handleShowScreensaver = function() {

    if (cache.inactiveTime >= screensaverStart) {
        if (main.utils.hasClass(cache.screensaver, 'u-hidden')) {
            main.utils.removeClass(cache.screensaver, 'u-hidden');
            main.animateScreensaver();
        }
    } else {
        cache.inactiveTime += 1;
        if (!main.utils.hasClass(cache.screensaver, 'u-hidden')) {
            main.utils.addClass(cache.screensaver, 'u-hidden');
            main.resetAnimateScreensaver();
        }
    }
};

main.animateScreensaver = function() {

    cache.screensaverFirstImageTimeout =  window.setTimeout(function() {
        cache.screensaverFirstImage.setAttribute("style", "opacity: 1");
    }, 100);

    cache.screensaverAnimation = window.setInterval(function() {

        // set previous image

        /*
            Perfect loop.
            From the 3rd image show the first and second image as well for a nice fade effect.
            Otherwise with the 1st and 2nd image, hide the last image
        */
        if(cache.activeImage >= 2) {
            cache.screensaverImages[cache.activeImage - 2].setAttribute("style", "opacity: 0");
            cache.screensaverImages[cache.screensaverImages.length - 2].setAttribute("style", "transition: opacity .440s cubic-bezier(0.550, 0.085, 0.680, 0.530);");
            cache.screensaverImages[cache.screensaverImages.length - 1].setAttribute("style", "transition: opacity .440s cubic-bezier(0.550, 0.085, 0.680, 0.530);");
        } else if(cache.activeImage == 0) {
            cache.screensaverImages[cache.screensaverImages.length - 2].setAttribute("style", "transition: none; opacity: 0");
            cache.screensaverImages[cache.screensaverImages.length - 1].setAttribute("style", "transition: none; opacity: 0");
        }

        // set image active;

        cache.screensaverImages[cache.activeImage].setAttribute("style", "opacity: 1");

        if(cache.activeImage == cache.screensaverImages.length - 1) {
            cache.activeImage = 0;
        } else {
            cache.activeImage++;
        }

    }, screensaverSpeed * 1000);
};

main.resetAnimateScreensaver = function() {
    clearInterval(cache.screensaverAnimation);
    clearTimeout(cache.screensaverFirstImageTimeout);
    for (var i = 0; i < cache.screensaverImages.length; i++) {
        cache.screensaverImages[i].setAttribute("style", "opacity: 0");
    }
    cache.screensaverFirstImage.setAttribute("style", "opacity: 0");
    cache.activeImage = 0;
};

main.hideScreensaver = function() {
    cache.inactiveTime = 0;
    main.resetAnimateScreensaver();
};

main.map = (function () {
    var mapElement;
    var geocoder;
    var map;

    var mapOptions = {
        zoom: 16,
        scrollwheel: false,
        draggable: true,
        mapTypeControlOptions: { mapTypeIds: [] },
        styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"administrative","elementType":"labels.icon","stylers":[{"saturation":"15"},{"visibility":"off"}]},{"featureType":"administrative.locality","elementType":"geometry","stylers":[{"visibility":"off"}]},{"featureType":"administrative.locality","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#fed500"},{"visibility":"on"}]}]
    };


    function initialize() {
        mapElement = document.getElementById('map-canvas');

        if (mapElement) {
            if (typeof google === 'undefined') {
                main.helpers.loadScript('//maps.googleapis.com/maps/api/js?v=3.exp' +
                    '&key=AIzaSyA6xydRXYd_R8eV5UHkOWfy0QtrnQ4idac&callback=main.map.initMap');
            } else {
                main.map.initMap();
            }
        }
    }

    function initMap() {
        var address = mapElement.getAttribute('data-address');

        geocoder = new google.maps.Geocoder();

        geocoder.geocode( { 'address' : address }, function( results, status ) {
            if ( status == google.maps.GeocoderStatus.OK ) {
                var latlng = results[0].geometry.location;

                map = new google.maps.Map(mapElement, main.helpers.extend(mapOptions, { center: latlng }));

                var markerImage = {
                    url: window.location.origin + '/assets/images/marker.png',
                    size: new google.maps.Size(44, 44),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(22, 54),
                };

                var marker = new google.maps.Marker({
                    position: latlng,
                    map: map,
                    icon: markerImage,
                });

                // initWindow(marker);

            } else {
                console.log( 'Geocode was not successful for the following reason: ' + status );
            }
        });
    }

    return {
        initialize: initialize,
        initMap: initMap,
    };
})();

main.utils = {
    hasClass: function (el, className) {
        if (el.classList)
            return el.classList.contains(className);
        else
            return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
    },
    addClass: function (el, className) {
        if (el.classList)
            el.classList.add(className);
        else if (!main.utils.hasClass(el, className))
            el.className += " " + className;
    },
    removeClass: function (el, className) {
        if (el.classList)
            el.classList.remove(className);
        else if (main.utils.hasClass(el, className)) {
            var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
            el.className = el.className.replace(reg, ' ');
        }
    },
    toggleClass: function (el, className) {
        if (el.classList) {
            el.classList.toggle(className);
        } else {
            var classes = el.className.split(' ');
            var existingIndex = classes.indexOf(className);

            if (existingIndex >= 0)
                classes.splice(existingIndex, 1);
            else
                classes.push(className);

            el.className = classes.join(' ');
        }
    }
};

main.helpers = {
    loadScript: function(url) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        document.body.appendChild(script);
    },
    extend: function(target) {

        target = Object(target);

        var argLen = arguments.length;
        var source, key;

        for (var i = 1; i < argLen; i++) {
            source = arguments[i];
            if (source !== null) {
                for (key in source) {
                    if (Object.prototype.hasOwnProperty.call(source, key)) {
                        target[key] = source[key];
                    }
                }
            }
        }

        return target;
    }
};

//True browser width and height (added scrollbar width/height)
main.viewport = function() {
    var e = window, a = 'inner';
    if (!('innerWidth' in window )) {
        a = 'client';
        e = document.documentElement || document.body;
    }
    return { width : e[ a+'Width' ] , height : e[ a+'Height' ] };
};

// Initialize main JS on docready
document.addEventListener("DOMContentLoaded",function(){main.init();});
